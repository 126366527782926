import React, { useState, useContext, ReactNode } from 'react';


export interface DeviceWizzardProps {
  name: string;
  buildingId: string;
  flatId: string;
  description: string;
  deviceType: 'heatMeter' | 'waterMeter' | '';
  networkType: 'TTN' | 'Emitel' | '';
  messageGenerationPeriodSeconds: number;
  loraStorePeriodSeconds: number;
  deviceTtnSettings: TTNSettingsProps;
  waterMeterProperties?: WaterMeterProps;
  heatMeterProperties?: HeatMeterProps;
  tenantId: string;
}

interface TTNSettingsProps {
  deviceEui: string;
  appKey: string;
  joinEui: string;
}

export interface WaterMeterProps {
  type?: string;
  diameter: string;
  measurementClass: string;
  flow: string;
  producer: string;
}

export interface HeatMeterProps {
  diameter: string;
  measurementClass: string;
  flow: string;
  length?: string;
  producer: string;
}

interface IDeviceWizzardContext {
  activeStep: number;
  deviceWizzard: Partial<DeviceWizzardProps> | null;
  updateDevice: (device: Partial<DeviceWizzardProps>) => void;
  handleNext: () => void;
  handleBack: () => void;
  clearState: () => void;
}

export const useDeviceWizzardContext = () => {
  return useContext(DeviceWizzardContext);
};

const DeviceWizzardContext = React.createContext({} as IDeviceWizzardContext);

export const DeviceWizzardProvider: React.FC<ReactNode> = ({ children }) => {
  const [deviceWizzard, setDeviceWizzard] =
    useState<Partial<DeviceWizzardProps> | null>(null);
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => ++prevActiveStep);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => --prevActiveStep);
  };

  const clearState = (): void => {
    setDeviceWizzard(null);
  };

  const updateDevice = (wizard: Partial<DeviceWizzardProps>) => {
    const updatedDevice = {
      ...deviceWizzard,
      ...wizard
    };

    if (updatedDevice) setDeviceWizzard(updatedDevice);
  };

  return (
    <DeviceWizzardContext.Provider
      value={{
        activeStep,
        deviceWizzard,
        updateDevice,
        handleNext,
        handleBack,
        clearState
      }}
    >
      {children}
    </DeviceWizzardContext.Provider>
  );
};
